import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./Foot";

function Contact() {
  const [verified, setVerified] = useState('');
  const [ercapacha, setErcapacha] = useState('')
  const [visible, setIsVisible] = useState(true);
  const captch = useRef(null);
  


  const key = '6LfS138lAAAAAF9xtStdEEvDEx-XBmkCnK22X5yv';

  function onChange() {
    setVerified(true);
  }




  const initalState = {
    name: "",
    email: "",
    message: ""
  };

  const [state, setState] = useState(initalState);
  const { name, email, message } = state;

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    if (verified === true) {
      axios
        .post("https://gizmoind.in/api/email.php", {
          name,
          email,
          message
        })
        .then(function (data) {
          // console.log(data);
          if (data.data.message === "Message Sent") {
            captch.current.reset()
            setState({ name: "", email: "", message: "" });
            toast.success('Thank You. We have received your message.', { position: toast.POSITION.BOTTOM_RIGHT });
           
          }
        })
    } else {
      setErcapacha("Please Verify CAPTCHA")
      setIsVisible(true);
      setTimeout(function () {
        setIsVisible(false);
      }, 3000);
    }

  };






  return (
    <>
      <ToastContainer />

      <div class="stricky-header stricked-menu main-menu">
        <div class="sticky-header__content" >
        </div>
      </div>

      <section class="page-header">
        <div class="page-header-bg" style={{ backgroundImage: 'url("assets/images/backgrounds/page-header-bg.jpg")' }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>Contact</li>
            </ul>
            <h2>Contact</h2>
          </div>
        </div>
      </section>

      <section class="contact-details">
        <div class="container">
          <ul class="list-unstyled contact-details__list">
            <li>
              <div class="contact-details__icon">
                <span class="icon-telephone" />
              </div>
              <div class="contact-details__content">
                <h4>
                  <a
                    href="tel:+919067777753"
                    class="contact-details__number-1"
                  >
                    +91 906 77777 53
                  </a>
                </h4>
              </div>
            </li>
            <li>
              <div class="contact-details__icon">
                <span class="icon-email" />
              </div>
              <div class="contact-details__content">
                <h4>
                  <a
                    href="mailto:info@company.com"
                    class="contact-details__number-1"
                  >
                    {" "}
                    info@gizmoind.in
                  </a>
                </h4>
              </div>
            </li>
            <li>
              <div class="contact-details__icon">
                <span class="icon-pin" />
              </div>
              <div class="contact-details__content">
                <h4>Rajkot (Gujarat) India.</h4>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section class="contact-page">
        <div class="contact-page-shape-1">
          <img src="assets/images/shapes/contact-page-shape-1.png" alt="" />
        </div>
        <div class="container">
          <div class="section-title text-center">
            <div class="section-sub-title-box">
              <p class="section-sub-title">Contact with us</p>
              <div class="section-title-shape-1">
                <img
                  src="assets/images/shapes/section-title-shape-1.png"
                  alt=""
                />
              </div>
            </div>
            <h2 class="section-title__title">
              Feel free to write our <br /> Gizmo experts
            </h2>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="contact-page__form">
                <form
                  onSubmit={handleSubmit}
                  class="comment-one__form contact-form-validated"
                >
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="comment-form__input-box">
                        <input
                          type="text"
                          value={state.name}
                          placeholder="Your name"
                          name="name"
                          onChange={changeHandler}
                          required
                        />
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="comment-form__input-box">
                        <input
                          type="email"
                          value={state.email}
                          placeholder="Email address"
                          name="email"
                          onChange={changeHandler}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="comment-form__input-box text-message-box">
                        <textarea
                          name="message"
                          value={state.message}
                          placeholder="Write a message"
                          defaultValue={""}
                          onChange={changeHandler}
                          required
                        />
                      </div>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-xl-6">
                      <div class="comment-form__input-box">
                        <ReCAPTCHA
                          ref={captch}
                          sitekey={key}
                          onChange={onChange}
                        

                        />
                        {visible && <p style={{ color: 'red' }}>{ercapacha}</p>}
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="comment-form__input-box">
                        <button
                          type="submit"
                          class="thm-btn comment-form__btn">
                          <i class="fa fa-arrow-right" /> Send a message
                        </button>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div >
      </section >
      <Footer />
    </>
  );
}

export default Contact;
