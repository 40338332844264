import { Link } from "react-router-dom";
import $ from 'jquery'

function Footer() {

  const Close = () => {
    $('.custom-cursor').removeClass('locked');
    $('.custom-cursor__cursor').removeClass('custom-cursor__hover');
    $('.mobile-nav__wrapper').removeClass('expanded');
  }




  return (
    <>

      <Link
        to="/#"
        data-target="html"
        class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></Link>

      <section class="trust-company">
        <div class="trust-company-shape-1">
          <img src="assets/images/shapes/traust-company-shape-1.png" alt="" />
        </div>
        <div class="trust-company-shape-2" />
        <div class="trust-company-shape-3" />
        <div class="trust-company-shape-4" />
        <div
          class="trust-company-bg jarallax"
          data-jarallax=""
          data-speed="0.2"
          data-imgposition="50% 0%"
          style={{ backgroundImage: 'url("assets/images/Factory.jpeg")' }}
        />
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-7">
              <div class="trust-company__left">
                {/*<div class="trust-company__video-link">*/}
                {/*    <a  class="video-popup">*/}
                {/*        <div class="trust-company__video-icon">*/}
                {/*<span class="fa fa-play"></span>*/}
                {/*            <i class="ripple"></i>*/}
                {/*        </div>*/}
                {/*    </a>*/}
                {/*</div>*/}
                <h2 class="trust-company__title">
                  Hundreds of customers trust our company
                </h2>
              </div>
            </div>
            <div class="col-xl-6 col-lg-5">
              <div class="trust-company__right">
                <ul class="list-unstyled trust-compay__points">
                  <li>
                    <div class="icon">
                      <span class="icon-tick" />
                    </div>
                    <div class="text">
                      <p>We only provide quality products</p>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <span class="icon-tick" />
                    </div>
                    <div class="text">
                      <p>We’ve expert &amp; certified staff</p>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <span class="icon-tick" />
                    </div>
                    <div class="text">
                      <p>We offer you free estimates</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="cta-one">
        <div class="container">
          <div class="cta-one__inner">
            <div class="cta-one__left">
              <p class="cta-one__sub-title">For More Details</p>
              <h3 class="cta-one__title">Contact Us</h3>
            </div>
            <div class="cta-one__btn-box">
              <Link to="/contact_us" class="thm-btn cta-one__btn">
                {" "}
                Contact
              </Link>
            </div>
          </div>
        </div>
      </section>
      <footer class="site-footer">
        <div class="site-footer-img-1">
          <img src="assets/images/resources/site-footer-img-1.png" alt="" />
        </div>
        <div class="site-footer-shape-1">
          <img src="assets/images/shapes/site-footer-shape-1.png" alt="" />
        </div>
        <div class="container">
          <div class="site-footer__top">
            <div class="row">
              <div
                class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div class="footer-widget__column footer-widget__about">
                  <div class="footer-widget__about-text-box">
                    <p
                      class="footer-widget__about-text"
                      style={{ fontSize: "30px !important" }}
                    >
                      Gizmo will navigate you safely to any corner of the world.
                    </p>
                  </div>
                  <div class="site-footer__social">
                    <a href="https://www.facebook.com/gizmoindustries/">
                      <i class="fab fa-facebook" />
                    </a>
                    <a href="https://www.linkedin.com/">
                      <i class="fab fa-linkedin-in" />
                    </a>
                    <a href="https://www.instagram.com/gizmoindustries/">
                      <i class="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="col-xl-2 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="200ms"
              >
                <div class="footer-widget__column footer-widget__explore clearfix">
                  <h3 class="footer-widget__title">Explore</h3>
                  <ul class="footer-widget__explore-list list-unstyled clearfix">
                    <li>
                      <Link to="/about_gizmo_industries">About </Link>
                    </li>
                    <li>
                      <Link to="/contact_us">Contact</Link>
                    </li>
                    <li>
                      <Link to="/our_products">Products</Link>
                    </li>
                    <li>
                      <Link to="/career">Career</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="300ms"
              >
                <div class="footer-widget__column footer-widget__newsletter clearfix">
                  <h3 class="footer-widget__title">News & Updates</h3>
                  <p class="footer-widget__newsletter-text">
                    News & Updates
                  </p>
                 
                </div>
              </div>
              <div
                class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="400ms"
              >
                <div class="footer-widget__column footer-widget__contact">
                  <h3 class="footer-widget__title">Contact</h3>
                  <p class="footer-widget__contact-text">
                    Rajkot (Gujarat) India.
                  </p>
                  <ul class="list-unstyled footer-widget__contact-list">
                    <li>
                      <div class="icon">
                        <i class="fa fa-envelope" />
                      </div>
                      <div class="text">
                        <p>
                          <a href="mailto:info@gizmoindustries.in">
                            {" "}
                            info@gizmoind.in
                          </a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="icon">
                        <i class="fas fa-phone-alt" />
                      </div>
                      <div class="text">
                        <p>
                          <a href="tel:9200368090">+91 906 77777 53</a>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="site-footer__bottom">
            <div class="row">
              <div class="col-xl-12">
                <div class="site-footer__bottom-inner">
                  <p class="site-footer__bottom-text">
                    © All Copyright 2023 <b>Gizmo Industries</b> | Managed By <a target="_blank" rel="noreferrer" href="https://gurusoftware.in/">Guru Software</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* /.page-wrapper */}
      <div class="mobile-nav__wrapper">
        <div class="mobile-nav__overlay mobile-nav__toggler" onClick={Close} />
        {/* /.mobile-nav__overlay */}
        <div class="mobile-nav__content">
          <span class="mobile-nav__close mobile-nav__toggler">
            <i class="fa fa-times" onClick={Close} />
          </span>
          <div class="logo-box">
            <Link to="/" aria-label="logo image">
              <img src="assets/images/resources/logo-3.png" width={143} alt="" />
            </Link>
          </div>
          {/* /.logo-box */}
          <div class="mobile-nav__container" />
          {/* /.mobile-nav__container */}
          <ul class="main-menu__list">
            <li class="current">
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about_gizmo_industries">About</Link>
            </li>
            <li>
              <Link to="/our_products">Product</Link>

            </li>

            <li>
              <Link to="/career">Career </Link>
            </li>
            <li>
              <Link to="/contact_us">Contact </Link>
            </li>
          </ul>
          <ul class="mobile-nav__contact list-unstyled">
            <li>
              <i class="fa fa-envelope" />
              <a href="mailto:info@gizmoind.in"> info@gizmoind.in</a>
            </li>
            <li>
              <i class="fa fa-phone-alt" />
              <a href="tel:666-888-0000">+91 906 77777 53</a>
            </li>
          </ul>
          {/* /.mobile-nav__contact */}
          <div class="mobile-nav__top">
            <div class="mobile-nav__social">
              <Link to="/#" class="fab fa-twitter" />
              <Link to="/" class="fab fa-facebook-square" />
              <Link to="/" class="fab fa-pinterest-p" />
              <Link to="/" class="fab fa-instagram" />
            </div>
          </div>
        </div>
        {/* /.mobile-nav__content */}
      </div>
      {/* /.mobile-nav__wrapper */}
    </>

  );
}

export default Footer;
