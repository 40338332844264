import "./App.css";
import Header from "./Layout/Head";
import Main from "./Layout/Home";
import About from "./Layout/About";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NoPage from "./Layout/NoPage";
import Product from "./Layout/Product";
import Career from "./Layout/Career";
import Contact from "./Layout/Contact";
import Anet from "./Layout/Agriculture_Net";
import Datepalmmesh from "./Layout/Date_Palm_mesh";
import FibcBulkBag from "./Layout/FIBC_Bulk_Bags";
import LenoMeshFabric from "./Layout/Leno_Mesh_Fabric";
import PlasticGranules from "./Layout/Plastic_Granules";
import PPWovenFabric from "./Layout/PP_Woven_Fabric";
import PPWovenSack from "./Layout/PP_Woven_Sack";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/about_gizmo_industries" element={<About />} />
        <Route exact path="our_products" element={<Product />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/contact_us" element={<Contact  />} />
        <Route exact path="/agriculture_net" element={<Anet />} />
        <Route exact path="/date_palm_mesh" element={<Datepalmmesh />} />
        <Route exact path="/fibc_bulk_bags" element={<FibcBulkBag />} />
        <Route exact path="/leno_mesh_fabric" element={<LenoMeshFabric />} />
        <Route exact path="/plastic_granules" element={<PlasticGranules />} />
        <Route exact path="/pp_woven_fabric" element={<PPWovenFabric />} />
        <Route exact path="/pp_woven_sack" element={<PPWovenSack />} />
        <Route path="*" element={<NoPage />} />
      </Routes>      
    </Router>
  );
}

// function App() {
//   return (
//     <div>
//       <Header />
//       {/* <Product /> */}
//       <Footer />
//     </div>
//   );
// }

export default App;
