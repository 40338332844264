import { Link } from "react-router-dom";
import Footer from "./Foot";
function PPWovenSack(){
return(
    <>
  <div class=  "stricky-header stricked-menu main-menu">
    <div class=  "sticky-header__content" />
    {/* /.sticky-header__content */}
  </div>
  {/* /.stricky-header */}
  {/*Page Header Start*/}
  <section class=  "page-header">
    <div
      class=  "page-header-bg"
      style={{
        backgroundImage: 'url("assets/images/backgrounds/page-header-bg.jpg")'
      }}
    ></div>
    <div class=  "container">
      <div class=  "page-header__inner">
        <ul class=  "thm-breadcrumb list-unstyled">
          <li>
            <Link to="/">Home </Link>
          </li>
          <li>
            <span>/</span>
          </li>
          <li>
            <Link to="/our_products">Products</Link>
          </li>
          <li>
            <span>/</span>
          </li>
          <li>PP Woven Sack</li>
        </ul>
        <h2>PP Woven Sack</h2>
      </div>
    </div>
  </section>
  {/*Page Header End*/}
  {/*Service Details Start*/}
  <section class=  "service-details">
    <div class=  "container">
      <div class=  "row">
        <div class=  "col-xl-4 col-lg-5">
          <div class=  "service-details__left">
            <div class=  "service-details__category">
              <ul class=  "service-details__category-list list-unstyled">
                <li>
                  <Link to="/pp_woven_fabric">
                    PP Woven Fabric <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li class=  "active">
                  <Link to="/pp_woven_sack">
                    PP Woven Sack <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/date_palm_mesh">
                    Date Palm Mesh <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/fibc_bulk_bags">
                    FIBC / Bulk Bag
                    <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/plastic_granules">
                    Plastic Granules <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/agriculture_net">
                    Agriculture Net <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/leno_mesh_fabric">
                    Leno Mesh Fabric <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
              </ul>
            </div>
          </div>
        </div>
        <div class=  "col-xl-8 col-lg-7">
          <div class=  "service-details__right">
            <div class=  "service-details__img">
              <img
                src="assets/images/products_cover/PP Woven Sack Cover.jpg"
                alt=""
              />
            </div>
            <div class=  "service-details__content">
              <h3 class=  "service-details__content-title">PP Woven Sack</h3>
              <p
                class=  "service-details__text-1"
                style={{ textAlign: "justify" }}
              >
                We are one of the largest manufactures, supplier and exporter of
                PP woven bags. By providing the customized packaging solution to
                all the various industries. Woven Polypropylene sacks are widely
                used for the packaging for building and construction materials
                as well as highly used for agriculture &amp; farming purposes,
                including products such as rice, sugar, flour, spices,
                fertilizers and other materials.
              </p>
              {/*<p class="service-details__text-2">When an unknown printer took a galley type and scrambled it to make a type specimen book.</p>*/}
              <p
                class=  "service-details__text-3"
                style={{ textAlign: "justify" }}
              >
                There are various names by which PP Woven sack is described,
                which depend upon country to country and also industry to
                industry. Woven Polypropylene sack is very prominent for its
                packaging utilities and its characteristics.
              </p>
            </div>
            <p
              class=  "service-details__text-4"
              style={{ textAlign: "justify" }}
            >
              We offer 100% custom designed polypropylene woven bags/sacks as
              per the required specifications of the customer. We can provide
              all the types of sizes, shapes or colors as per your requirement
              making your bag 100% custom designed. We supply various types of
              woven polypropylene sacks such as regular uncoated PP bags,
              laminated (coated) PP bags, BOPP (laminated PP bags with printing)
              and PP bags with liners. In addition to this we can offer
              customized color printing on each side making the bag look highly
              attractive and quality bag.
            </p>
            <p
              class=  "service-details__text-5"
              style={{ textAlign: "justify" }}
            >
              Our Polypropylene woven fabric's construction makes our PP bags
              very strong, tear resistant, light weighted, breathable and most
              importantly extremely cost effective.
            </p>
            <p class=  "prod">PP WOVEN BAGS</p>
            <div class=  "service-details__benefits">
              <div class=  "row">
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Sack 1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Sack 2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Sack 3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Sack 4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class=  "prod">BOPP LAMINATED BAGS </p>
            <div class=  "service-details__benefits">
              <div class=  "row">
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Sack 7.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Sack 8.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class=  "prod">PP WOVEN D-CUT BAGS</p>
            <div class=  "service-details__benefits">
              <div class=  "row">
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Sack 5.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Sack 6.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</>

);
}

export default PPWovenSack;