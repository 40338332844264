import { Link } from "react-router-dom";
import Footer from "./Foot";
function PlasticGranules(){
    return(
        <>
  <div class=  "stricky-header stricked-menu main-menu">
    <div class=  "sticky-header__content" />
    {/* /.sticky-header__content */}
  </div>
  {/* /.stricky-header */}
  {/*Page Header Start*/}
  <section class=  "page-header">
    <div
      class=  "page-header-bg"
      style={{
        backgroundImage: 'url("assets/images/backgrounds/page-header-bg.jpg")'
      }}
    ></div>
    <div class=  "container">
      <div class=  "page-header__inner">
        <ul class=  "thm-breadcrumb list-unstyled">
          <li>
            <Link to="/">Home </Link>
          </li>
          <li>
            <span>/</span>
          </li>
          <li>
            <Link to="/our_products">Products </Link>
          </li>
          <li>
            <span>/</span>
          </li>
          <li>Plastic Granules</li>
        </ul>
        <h2>Plastic Granules</h2>
      </div>
    </div>
  </section>
  {/*Page Header End*/}
  {/*Service Details Start*/}
  <section class=  "service-details">
    <div class=  "container">
      <div class=  "row">
        <div class=  "col-xl-4 col-lg-5">
          <div class=  "service-details__left">
            <div class=  "service-details__category">
              <ul class=  "service-details__category-list list-unstyled">
                <li>
                  <Link to="/pp_woven_fabric">
                    PP Woven Fabric <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/pp_woven_sack">
                    PP Woven Sack <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/date_palm_mesh">
                    Date Palm Mesh <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/fibc_bulk_bags">
                    FIBC / Bulk Bag
                    <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li class=  "active">
                  <Link to="/plastic_granules">
                    Plastic Granules <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/agriculture_net">
                    Agriculture Net <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/leno_mesh_fabric">
                    Leno Mesh Fabric <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
              </ul>
            </div>
          </div>
        </div>
        <div class=  "col-xl-8 col-lg-7">
          <div class=  "service-details__right">
            <div class=  "service-details__img">
              <img
                src="assets/images/products_cover/Plastic Granules Cover.jpg"
                alt=""
              />
            </div>
            <div class=  "service-details__content">
              <h3 class=  "service-details__content-title">
                Plastic Granules
              </h3>
              <p
                class=  "service-details__text-1"
                style={{ textAlign: "justify" }}
              >
                Plastic granules… like PP, PPCP, HD, HDPE, LLDPE etc..that may
                be virgin or recycled. We are here to fulfill your need in a
                bulk as well. You simply need to provide us the grade and get
                the raw material.
              </p>
            </div>
          </div>
          <div class=  "service-details__benefits">
            <div class=  "row">
              <div class=  "col-xl-6">
                <div class=  "service-details__benefits-single">
                  <div class=  "service-details__benefits-img">
                    <img
                      src="assets/images/product_images/Plastic Granules 1.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class=  "col-xl-6">
                <div class=  "service-details__benefits-single">
                  <div class=  "service-details__benefits-img">
                    <img
                      src="assets/images/product_images/Plastic Granules 2.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</>

    );
}

export default PlasticGranules;