import { Link } from "react-router-dom";
import Footer from "./Foot";

function Product(){
return(
    <>
  <div class=  "stricky-header stricked-menu main-menu">
    <div class=  "sticky-header__content" />
    {/* /.sticky-header__content */}
  </div>
  {/* /.stricky-header */}
  {/*Page Header Start*/}
  <section class=  "page-header">
    <div
      class=  "page-header-bg"
      style={{
        backgroundImage: 'url("assets/images/backgrounds/page-header-bg.jpg")'
      }}
    ></div>
    <div class=  "container">
      <div class=  "page-header__inner">
        <ul class=  "thm-breadcrumb list-unstyled">
          <li>
            <Link to="/">Home </Link>
          </li>
          <li>
            <span>/</span>
          </li>
          <li>Products</li>
        </ul>
        <h2>Our Products</h2>
      </div>
    </div>
  </section>
  {/*Page Header End*/}
  {/*Services Page Start*/}
  <section class=  "services-page">
    <div class=  "container">
      <div class=  "row">
        <div
          class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
          data-wow-delay="100ms"
        >
          <div class=  "services-one__single">
            <div class=  "services-one__img">
              <img
                src="assets/images/product_images/PP Woven Fabric 1.jpg"
                alt=""
              />
            </div>
            <div class=  "services-one__content">
              <h3 class=  "services-one__title">
                <Link to="/pp_woven_fabric">PP Woven Fabric</Link>
              </h3>
              <div class=  "services-one__read-more">
                <Link to="/pp_woven_fabric">
                  Read More <i class=  "fa fa-arrow-right" />
                  </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
          data-wow-delay="600ms"
        >
          <div class=  "services-one__single">
            <div class=  "services-one__img">
              <img
                src="assets/images/product_images/Agriculture Net 1.jpg"
                alt=""
              />
            </div>
            <div class=  "services-one__content">
              <h3 class=  "services-one__title">
                <Link to="/agriculture_net">Agriculture net</Link>
              </h3>
              <div class=  "services-one__read-more">
                <Link to="/agriculture_net">
                  Read More <i class=  "fa fa-arrow-right" />
                  </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
          data-wow-delay="600ms"
        >
          <div class=  "services-one__single">
            <div class=  "services-one__img">
              <img
                src="assets/images/product_images/Leno Mesh Fabric 1.jpg"
                alt=""
              />
            </div>
            <div class=  "services-one__content">
              <h3 class=  "services-one__title">
                <Link to="/leno_mesh_fabric">Leno Mesh Fabric</Link>
              </h3>
              <div class=  "services-one__read-more">
                <Link to="/leno_mesh_fabric">
                  Read More <i class=  "fa fa-arrow-right" />
                  </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
          data-wow-delay="200ms"
        >
          <div class=  "services-one__single">
            <div class=  "services-one__img">
              <img
                src="assets/images/product_images/PP Woven Sack 5.jpg"
                alt=""
              />
            </div>
            <div class=  "services-one__content">
              <h3 class=  "services-one__title">
                <Link to="/pp_woven_sack">PP Woven Sack</Link>
              </h3>
              <div class=  "services-one__read-more">
                <Link to="/pp_woven_sack">
                  Read More <i class=  "fa fa-arrow-right" />
                  </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
          data-wow-delay="300ms"
        >
          <div class=  "services-one__single">
            <div class=  "services-one__img">
              <img
                src="assets/images/product_images/Date Palm Mesh 1.jpg"
                alt=""
              />
            </div>
            <div class=  "services-one__content">
              <h3 class=  "services-one__title">
                <Link to="/date_palm_mesh">Date Palm Mesh</Link>
              </h3>
              <div class=  "services-one__read-more">
                <Link to="/date_palm_mesh">
                  Read More <i class=  "fa fa-arrow-right" />
                  </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
          data-wow-delay="400ms"
        >
          <div class=  "services-one__single">
            <div class=  "services-one__img">
              <img
                src="assets/images/product_images/FIBC Bulk Bag 2.jpg"
                alt=""
              />
            </div>
            <div class=  "services-one__content">
              <h3 class=  "services-one__title">
                <Link to="/fibc_bulk_bags">Fibc / Bulk bag</Link>
              </h3>
              <div class=  "services-one__read-more">
                <Link to="/fibc_bulk_bags">
                  Read More <i class=  "fa fa-arrow-right" />
                  </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
          data-wow-delay="500ms"
        >
          <div class=  "services-one__single">
            <div class=  "services-one__img">
              <img
                src="assets/images/product_images/Plastic Granules 1.jpg"
                alt=""
              />
            </div>
            <div class=  "services-one__content">
              <h3 class=  "services-one__title">
                <Link to="/plastic_granules">Plastic Granules</Link>
              </h3>
              <div class=  "services-one__read-more">
                <Link to="/plastic_granules">
                  Read More <i class=  "fa fa-arrow-right" />
                  </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</>

);
}

export default Product;