import { Link } from "react-router-dom";
import Footer from "./Foot";

function FibcBulkBag(){
    return(
<>
  <div class=  "stricky-header stricked-menu main-menu">
    <div class=  "sticky-header__content" />
    {/* /.sticky-header__content */}
  </div>
  {/* /.stricky-header */}
  {/*Page Header Start*/}
  <section class=  "page-header">
    <div
      class=  "page-header-bg"
      style={{
        backgroundImage: 'url("assets/images/backgrounds/page-header-bg.jpg")'
      }}
    ></div>
    <div class=  "container">
      <div class=  "page-header__inner">
        <ul class=  "thm-breadcrumb list-unstyled">
          <li>
            <Link to="/">Home </Link>
          </li>
          <li>
            <span>/</span>
          </li>
          <li>
            <Link to="/our_products">Products</Link>
          </li>
          <li>
            <span>/</span>
          </li>
          <li>FIBC / Bulk Bags</li>
        </ul>
        <h2>FIBC / Bulk Bags</h2>
      </div>
    </div>
  </section>
  {/*Page Header End*/}
  {/*Service Details Start*/}
  <section class=  "service-details">
    <div class=  "container">
      <div class=  "row">
        <div class=  "col-xl-4 col-lg-5">
          <div class=  "service-details__left">
            <div class=  "service-details__category">
              <ul class=  "service-details__category-list list-unstyled">
                <li>
                  <Link to="/pp_woven_fabric">
                    PP Woven Fabric <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/pp_woven_sack">
                    PP Woven Sack <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/date_palm_mesh">
                    Date Palm Mesh <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li class=  "active">
                  <Link to="/fibc_bulk_bags">
                    FIBC / Bulk Bag
                    <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/plastic_granules">
                    Plastic Granules <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/agriculture_net">
                    Agriculture Net <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/leno_mesh_fabric">
                    Leno Mesh Fabric <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
              </ul>
            </div>
          </div>
        </div>
        <div class=  "col-xl-8 col-lg-7">
          <div class=  "service-details__right">
            <div class=  "service-details__img">
              <img
                src="assets/images/products_cover/FIBC Bulk Bag Cover.jpg"
                alt=""
              />
            </div>
            <div class=  "service-details__content">
              <h3 class=  "service-details__content-title">
                FIBC / Bulk Bag
              </h3>
              <p
                class=  "service-details__text-1"
                style={{ textAlign: "justify" }}
              >
                FIBC (Flexible Intermediate Bulk Container) is also referred as
                Bulk bag, Jumbo bag or Super Sack, with a holding capacity from
                about half up to two tones. The FIBC/bulk bag gives a very
                important edge in the packaging industry, from storing, handing
                and packaging of dry bulk and liquid bulk goods.
              </p>
              <p
                class=  "service-details__text-3"
                style={{ textAlign: "justify" }}
              >
                The Bulk bags has proven to be one of the most cost eective and
                economical packing alternative, providing the customized design
                to the bags considering the type of material, its filling and
                discharge options suitable for the highly optimization of the
                material and its distribution systems.
              </p>
            </div>
            <div class=  "service-details__benefits-content">
              <ul class=  "list-unstyled service-details__benefits-points">
                <li>
                  <div class=  "icon">
                    <i class=  "fa fa-arrow-right" />
                  </div>
                  <div class=  "text">
                    <p>Safe Working Load : 100kg to 2100 kgs</p>
                  </div>
                </li>
                <li>
                  <div class=  "icon">
                    <i class=  "fa fa-arrow-right" />
                  </div>
                  <div class=  "text">
                    <p>Size : Customised</p>
                  </div>
                </li>
                <li>
                  <div class=  "icon">
                    <i class=  "fa fa-arrow-right" />
                  </div>
                  <div class=  "text">
                    <p>Safety Factor : As per customer requirement</p>
                  </div>
                </li>
                <li>
                  <div class=  "icon">
                    <i class=  "fa fa-arrow-right" />
                  </div>
                  <div class=  "text">
                    <p>Material : PP UV Stabilized</p>
                  </div>
                </li>
                <li>
                  <div class=  "icon">
                    <i class=  "fa fa-arrow-right" />
                  </div>
                  <div class=  "text">
                    <p>PE Liners : 40-200 microns thickness</p>
                  </div>
                </li>
                <li>
                  <div class=  "icon">
                    <i class=  "fa fa-arrow-right" />
                  </div>
                  <div class=  "text">
                    <p>Quality : Standard</p>
                  </div>
                </li>
                <li>
                  <div class=  "icon">
                    <i class=  "fa fa-arrow-right" />
                  </div>
                  <div class=  "text">
                    <p>
                      {" "}
                      Loops, Filling &amp; Discharge Options : As per customer
                      requirement
                    </p>
                  </div>
                </li>
                <li>
                  <div class=  "icon">
                    <i class=  "fa fa-arrow-right" />
                  </div>
                  <div class=  "text">
                    <p>Packing : Bale or Pallets</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class=  "service-details__benefits">
              <div class=  "row">
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/FIBC Bulk Bag 1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/FIBC Bulk Bag 2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/FIBC Bulk Bag 3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/FIBC Bulk Bag 4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</>
    );
}

export default FibcBulkBag;