import { Link } from "react-router-dom";
import Footer from "./Foot";

function LenoMeshFabric(){
    return(
        <>
  <div class=  "stricky-header stricked-menu main-menu">
    <div class=  "sticky-header__content" />
    {/* /.sticky-header__content */}
  </div>
  {/* /.stricky-header */}
  {/*Page Header Start*/}
  <section class=  "page-header">
    <div
      class=  "page-header-bg"
      style={{
        backgroundImage: 'url("assets/images/backgrounds/page-header-bg.jpg")'
      }}
    ></div>
    <div class=  "container">
      <div class=  "page-header__inner">
        <ul class=  "thm-breadcrumb list-unstyled">
          <li>
            <Link to="/">Home </Link>
          </li>
          <li>
            <span>/</span>
          </li>
          <li>
            <Link to="/our_products">Products</Link>
          </li>
          <li>
            <span>/</span>
          </li>
          <li>Leno Mesh Fabric</li>
        </ul>
        <h2>Leno Mesh Fabric</h2>
      </div>
    </div>
  </section>
  {/*Page Header End*/}
  {/*Service Details Start*/}
  <section class=  "service-details">
    <div class=  "container">
      <div class=  "row">
        <div class=  "col-xl-4 col-lg-5">
          <div class=  "service-details__left">
            <div class=  "service-details__category">
              <ul class=  "service-details__category-list list-unstyled">
                <li>
                  <Link to="/pp_woven_fabric">
                    PP Woven Fabric <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/pp_woven_sack">
                    PP Woven Sack <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/date_palm_mesh">
                    Date Palm Mesh <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/fibc_bulk_bags">
                    FIBC / Bulk Bag
                    <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/plastic_granules">
                    Plastic Granules <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/agriculture_net">
                    Agriculture Net <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li class=  "active">
                  <Link to="/leno_mesh_fabric">
                    Leno Mesh Fabric <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
              </ul>
            </div>
          </div>
        </div>
        <div class=  "col-xl-8 col-lg-7">
          <div class=  "service-details__right">
            <div class=  "service-details__img">
              <img
                src="assets/images/products_cover/Leno Mesh Fabric Cover.jpg"
                alt=""
              />
            </div>
            <div class=  "service-details__content">
              <h3 class=  "service-details__content-title">
                Leno Mesh Fabric
              </h3>
              <p
                class=  "service-details__text-1"
                style={{ textAlign: "justify" }}
              >
                Leno mesh bags are generally famous as specialty items and are
                genuinely valuable in making various types of artworks,
                comparative as covers. They're appropriate, yet not for quite a
                while, as the mesh will often get grated and free its solidarity
                after some time. We are, make this plastic mesh that is more
                solid, stronger, and appropriate to hold heavier items than the
                slim plastic mesh. The cost of plastic mesh bags changes
                contingent upon various variables. As the nature of the mesh
                increments, so does the cost. Thick plastic mesh bags are more
                valuable than flimsy plastic mesh packs because of their more
                grounded quality.
              </p>
            </div>
            <p class=  "service-details__text-4">
              Leno bags scores a definite edge over all other conventional modes
              of packaging perishable products. It is strong, light-weight and
              can withstand long hours of transportation without any wastage or
              spillage. It also provides excellent ventilation and is resistant
              to any fungus or insects thus preserving the life of the packed
              content.
            </p>
            <div class=  "service-details__benefits">
              <div class=  "row">
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/Leno Mesh Fabric 2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/Leno Mesh Fabric 1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/Leno Mesh Fabric 3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</>

    );
}

export default LenoMeshFabric;