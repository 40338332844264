import { Link } from "react-router-dom";
import Footer from "./Foot";

function Main() {
    return (
      <>
  <section class=  "main-slider clearfix">
    <div
      class=  "swiper-container thm-swiper__slider"
      data-swiper-options='{"slidesPerView": 1, "loop": true,
          "effect": "fade",
          "pagination": {
          "el": "#main-slider-pagination",
          "type": "bullets",
          "clickable": true
          },
          "navigation": {
          "nextEl": "#main-slider__swiper-button-next",
          "prevEl": "#main-slider__swiper-button-prev"
          },
          "autoplay": {
          "delay": 5000
          }}'
    >
      <div class=  "swiper-wrapper">
        <div class=  "swiper-slide">
          <div
            class=  "image-layer"
            style={{
              backgroundImage: 'url("assets/images/Slider/PP Woven Fabric.png")'
            }}
          />
          <div class=  "container">
            <div class=  "row">
              <div class=  "col-xl-7 col-lg-8">
                <div class=  "main-slider__content">
                  {/*<p class="main-slider__sub-title">Gizmo</p>*/}
                  <h2 class=  "main-slider__title headfont">
                    PP Woven Fabric
                  </h2>
                  <div class=  "main-slider__btn-box">
                    <Link to="/our_products" class=  "thm-btn main-slider__btn">
                      {" "}
                      <i class=  "fa fa-arrow-right" />
                      Discover more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=  "swiper-slide">
          <div
            class=  "image-layer"
            style={{
              backgroundImage: 'url("assets/images/Slider/Agriculture Net.png")'
            }}
          />
          <div class=  "container">
            <div class=  "row">
              <div class=  "col-xl-7 col-lg-8">
                <div class=  "main-slider__content">
                  {/*<p class="main-slider__sub-title">Gizmo</p>*/}
                  <h2 class=  "main-slider__title headfont">
                    Agriculture Net
                  </h2>
                  <div class=  "main-slider__btn-box">
                    <Link to="/our_products" class=  "thm-btn main-slider__btn">
                      {" "}
                      <i class=  "fa fa-arrow-right" />
                      Discover more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=  "swiper-slide">
          <div
            class=  "image-layer"
            style={{
              backgroundImage: 'url("assets/images/Slider/PP Woven Sack.png")'
            }}
          />
          <div class=  "container">
            <div class=  "row">
              <div class=  "col-xl-7 col-lg-8">
                <div class=  "main-slider__content headfont">
                  {/*<p class="main-slider__sub-title">Gizmo</p>*/}
                  <h2 class=  "main-slider__title">PP Woven Sack</h2>
                  <div class=  "main-slider__btn-box">
                    <Link to="/our_products" class=  "thm-btn main-slider__btn">
                      {" "}
                      <i class=  "fa fa-arrow-right" />
                      Discover more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=  "swiper-slide">
          <div
            class=  "image-layer"
            style={{
              backgroundImage: 'url("assets/images/Slider/Date Palm Mesh.png")'
            }}
          />
          <div class=  "container">
            <div class=  "row">
              <div class=  "col-xl-7 col-lg-8">
                <div class=  "main-slider__content">
                  {/*<p class="main-slider__sub-title">Gizmo</p>*/}
                  <h2 class=  "main-slider__title headfont">
                    Date Palm Mesh
                  </h2>
                  <div class=  "main-slider__btn-box">
                    <Link to="/our_products" class=  "thm-btn main-slider__btn">
                      {" "}
                      <i class=  "fa fa-arrow-right" />
                      Discover more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=  "swiper-slide">
          <div
            class=  "image-layer"
            style={{
              backgroundImage: 'url("assets/images/Slider/Fibc Bulk bag.png")'
            }}
          />
          <div class=  "container">
            <div class=  "row">
              <div class=  "col-xl-7 col-lg-8">
                <div class=  "main-slider__content">
                  {/*<p class="main-slider__sub-title">Gizmo</p>*/}
                  <h2 class=  "main-slider__title headfont">
                    Fibc / Bulk bag
                  </h2>
                  <div class=  "main-slider__btn-box">
                    <Link to="/our_products" class=  "thm-btn main-slider__btn">
                      {" "}
                      <i class=  "fa fa-arrow-right" />
                      Discover more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=  "swiper-slide">
          <div
            class=  "image-layer"
            style={{
              backgroundImage:
                'url("assets/images/Slider/Leno Mesh Fabric.png")'
            }}
          />
          <div class=  "container">
            <div class=  "row">
              <div class=  "col-xl-7 col-lg-8">
                <div class=  "main-slider__content">
                  {/*<p class="main-slider__sub-title">Gizmo</p>*/}
                  <h2 class=  "main-slider__title headfont">
                    Leno Mesh Fabric
                  </h2>
                  <div class=  "main-slider__btn-box">
                    <Link to="/our_products" class=  "thm-btn main-slider__btn">
                      {" "}
                      <i class=  "fa fa-arrow-right" />
                      Discover more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* If we need navigation buttons */}
      <div class=  "main-slider__nav">
        <div
          class=  "swiper-button-prev"
          id="main-slider__swiper-button-next"
        >
          <i class=  "icon-left-arrow" />
        </div>
        <div
          class=  "swiper-button-next"
          id="main-slider__swiper-button-prev"
        >
          <i class=  "icon-right-arrow" />
        </div>
      </div>
    </div>
  </section>
  {/*Main Slider End*/}
  {/*Feature One Start*/}
  <section class=  "feature-one">
    <div class=  "container">
      <div class=  "feature-one__inner">
        <div class=  "feature-one__dot">
          <img src="assets/images/shapes/feature-one-dot.png" alt="" />
        </div>
        <div class=  "row">
          {/*Feature One Single Start*/}
          <div
            class=  "col-xl-4 col-lg-4 wow fadeInUp"
            data-wow-delay="100ms"
          >
            <div class=  "feature-one__single">
              <div class=  "feature-one__top">
                <div class=  "feature-one__icon">
                  <span class=  "icon-roof-5" />
                </div>
                <div class=  "feature-one__title-box">
                  <h3 class=  "feature-one__title">
                    <Link to="/about">Contentment</Link>
                  </h3>
                </div>
              </div>
              <div class=  "feature-one__single-inner">
                <p class=  "feature-one__text">Our Foremost goal is</p>
                {/*<div class="feature-one__read-more">*/}
                {/*    <a href="about.php">Read More <i class="fa fa-arrow-right"></i></a>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          {/*Feature One Single End*/}
          {/*Feature One Single Start*/}
          <div
            class=  "col-xl-4 col-lg-4 wow fadeInUp"
            data-wow-delay="200ms"
          >
            <div class=  "feature-one__single">
              <div class=  "feature-one__top">
                <div class=  "feature-one__icon">
                  <span class=  "icon-construction-worker" />
                </div>
                <div class=  "feature-one__title-box">
                  <h3 class=  "feature-one__title">
                    <Link to="/about_gizmo_industries">Perfection</Link>
                  </h3>
                </div>
              </div>
              <div class=  "feature-one__single-inner">
                <p class=  "feature-one__text">Believe in achieving</p>
              </div>
            </div>
          </div>
          {/*Feature One Single End*/}
          {/*Feature One Single Start*/}
          <div
            class=  "col-xl-4 col-lg-4 wow fadeInUp"
            data-wow-delay="300ms"
          >
            <div class=  "feature-one__single">
              <div class=  "feature-one__top">
                <div class=  "feature-one__icon">
                  <span class=  "icon-online-registration" />
                </div>
                <div class=  "feature-one__title-box">
                  <h3 class=  "feature-one__title">
                    <Link to="/contact_us">Trust </Link>
                  </h3>
                </div>
              </div>
              <div class=  "feature-one__single-inner">
                <p class=  "feature-one__text">We nurture relationship</p>
              </div>
            </div>
          </div>
          {/*Feature One Single End*/}
        </div>
      </div>
    </div>
  </section>
  {/*Feature One End*/}
  {/*About One Start*/}
  <section class=  "about-one">
    {/*<div class="about-one__shape float-bob-x">*/}
    {/*    <img src="assets/images/shapes/about-one-shape.png" alt="">*/}
    {/*</div>*/}
    <div class=  "container">
      <div class=  "row">
        <div class=  "col-xl-6">
          <div class=  "about-one__left">
            <div
              class=  "about-one__img-box wow slideInLeft"
              data-wow-delay="100ms"
              data-wow-duration="2500ms"
            >
              <div class=  "about-one__img">
                <img src="assets/images/backgrounds/about_gizmo.png" alt="" />
              </div>
              <div class=  "about-one__line">
                <img src="assets/images/shapes/about-one-line.png" alt="" />
              </div>
              <div class=  "about-one__satisfied">
                <div class=  "about-one__satisfied-inner">
                  <div class=  "about-one__satisfied-shape">
                    <img
                      src="assets/images/shapes/about-one-satisfied-shape-1.png"
                      alt=""
                    />
                  </div>
                  <div class=  "about-one__satisfied-content">
                    <div class=  "about-one__satisfied-count-box">
                    <h3 class="odometer" data-count="09">09</h3>
                      <span class=  "about-one__satisfied-percent">+</span>
                    </div>
                    <p class=  "about-one__satisfied-text">
                      Years Experience
                    </p>
                  </div>
                </div>
              </div>
              <div class=  "about-one__big-text">Industy</div>
            </div>
          </div>
        </div>
        <div class=  "col-xl-6">
          <div class=  "about-one__right">
            <div class=  "section-title text-left">
              <div class=  "section-sub-title-box">
                <p class=  "section-sub-title">About Gizmo</p>
                <div class=  "section-title-shape-1">
                  <img
                    src="assets/images/shapes/section-title-shape-1.png"
                    alt=""
                  />
                </div>
              </div>
              <h2 class=  "section-title__title headfont">
                Experienced &amp; quality material providers
              </h2>
            </div>
            <p class=  "about-one__text">
              Gizmo Industries was conceptualized very early and in other way it
              is a brain child – at present –We are already is in this field
              since the first decade of 21st century. The entire lap of time was
              a process of gathering and gaining thorough and deepest knowledge
              in the field of such commodities.
            </p>
            <ul class=  "list-unstyled about-one__points">
              <li>
                <div class=  "icon-box">
                  <div class=  "icon">
                    <span class=  "icon-confirmation" />
                  </div>
                  <div class=  "text-box">
                    <p> Our foremost goal is Contentment</p>
                  </div>
                </div>
                {/*<div class="text-box-two">*/}
                {/*    <p>Lorem ipsum dolor sit ame sedme consectetur nod.</p>*/}
                {/*</div>*/}
              </li>
            </ul>
            <ul class=  "list-unstyled about-one__points">
              <li>
                <div class=  "icon-box">
                  <div class=  "icon">
                    <span class=  "icon-confirmation" />
                  </div>
                  <div class=  "text-box">
                    <p> Believe in achieving Perfection</p>
                  </div>
                </div>
                {/*<div class="text-box-two">*/}
                {/*    <p>Lorem ipsum dolor sit ame sedme consectetur nod.</p>*/}
                {/*</div>*/}
              </li>
            </ul>
            <ul class=  "list-unstyled about-one__points">
              <li>
                <div class=  "icon-box">
                  <div class=  "icon">
                    <span class=  "icon-confirmation" />
                  </div>
                  <div class=  "text-box">
                    <p> Believe that our communication Belief</p>
                  </div>
                </div>
                {/*<div class="text-box-two">*/}
                {/*    <p>Lorem ipsum dolor sit ame sedme consectetur nod.</p>*/}
                {/*</div>*/}
              </li>
            </ul>
            <ul class=  "list-unstyled about-one__points">
              <li>
                <div class=  "icon-box">
                  <div class=  "icon">
                    <span class=  "icon-confirmation" />
                  </div>
                  <div class=  "text-box">
                    <p> We nurture relationship Trust</p>
                  </div>
                </div>
                {/*<div class="text-box-two">*/}
                {/*    <p>Lorem ipsum dolor sit ame sedme consectetur nod.</p>*/}
                {/*</div>*/}
              </li>
            </ul>
            <Link to="/our_products" class=  "thm-btn about-one__btn">
              {" "}
              <i class=  "fa fa-arrow-right" /> Discover more
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*About One End*/}
  <section class=  "process">
    <div
      class=  "process-bg"
      style={{ backgroundImage: 'url("assets/images/map.jpg")' }}
    />
    <div class=  "container">
      <div class=  "row">
        <div style={{ paddingBottom: 90 }} class=  "process__details">
          &nbsp;
          <h2
            style={{ color: "white", fontSize: "35px !important" }}
            class=  "section-title__title headfont"
          >
            Gizmo Industries will navigate you safely to any corner of the world
          </h2>
          &nbsp;
        </div>
      </div>
    </div>
  </section>
  {/*Services One Start*/}
  <section class=  "services-one">
    <div class=  "services-one-shape-1 float-bob-x">
      <img src="assets/images/shapes/services-one-shape-1.png" alt="" />
    </div>
    <div class=  "services-one-shape-2">
      <img src="assets/images/shapes/services-one-shape-2.png" alt="" />
    </div>
    <div class=  "container">
      <div class=  "services-one__top">
        <div class=  "row">
          <div class=  "col-xl-7 col-lg-6">
            <div class=  "services-one__top-left">
              <div class=  "section-title text-left">
                <div class=  "section-sub-title-box">
                  <p class=  "section-sub-title">Our Products</p>
                  <div class=  "section-title-shape-1">
                    <img
                      src="assets/images/shapes/section-title-shape-1.png"
                      alt=""
                    />
                  </div>
                </div>
                <h2 class=  "section-title__title headfont">
                  We’re providing quality Products
                </h2>
              </div>
            </div>
          </div>
          {/*<div class="col-xl-5 col-lg-6">*/}
          {/*    <div class="services-one__top-right">*/}
          {/*        <p class="services-one__top-right-text">Nulla commodo dolor massa, vel pellentesque nulla congue quis. Fusce ut convallis diam. Nam id tortor et nunc tempor faucibus. Sed eu leo egestas.</p>*/}
          {/*    </div>*/}
          {/*</div>*/}
        </div>
      </div>
      <div class=  "services-one__bottom">
        <div class=  "row">
          <div
            class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
            data-wow-delay="100ms"
          >
            <div class=  "services-one__single">
              <div class=  "services-one__img">
                <img
                  src="assets/images/product_images/PP Woven Fabric 1.jpg"
                  alt=""
                />
              </div>
              <div class=  "services-one__content">
                <h3 class=  "services-one__title">
                  <Link to="/pp_woven_fabric">PP Woven Fabric</Link>
                </h3>
                <div class=  "services-one__read-more">
                  <Link to="/pp_woven_fabric">
                    Read More <i class=  "fa fa-arrow-right" />
                    </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
            data-wow-delay="600ms"
          >
            <div class=  "services-one__single">
              <div class=  "services-one__img">
                <img
                  src="assets/images/product_images/Agriculture Net 1.jpg"
                  alt=""
                />
              </div>
              <div class=  "services-one__content">
                <h3 class=  "services-one__title">
                  <Link to="/agriculture_net">Agriculture net</Link>
                </h3>
                <div class=  "services-one__read-more">
                  <Link to="/agriculture_net">
                    Read More <i class=  "fa fa-arrow-right" />
                    </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
            data-wow-delay="600ms"
          >
            <div class=  "services-one__single">
              <div class=  "services-one__img">
                <img
                  src="assets/images/product_images/Leno Mesh Fabric 1.jpg"
                  alt=""
                />
              </div>
              <div class=  "services-one__content">
                <h3 class=  "services-one__title">
                  <Link to="/leno_mesh_fabric">Leno Mesh Fabric</Link>
                </h3>
                <div class=  "services-one__read-more">
                  <Link to="/leno_mesh_fabric">
                    Read More <i class=  "fa fa-arrow-right" />
                    </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
            data-wow-delay="200ms"
          >
            <div class=  "services-one__single">
              <div class=  "services-one__img">
                <img
                  src="assets/images/product_images/PP Woven Sack 5.jpg"
                  alt=""
                />
              </div>
              <div class=  "services-one__content">
                <h3 class=  "services-one__title">
                  <Link to="/pp_woven_sack">PP Woven Sack</Link>
                </h3>
                <div class=  "services-one__read-more">
                  <Link to="/pp_woven_sack">
                    Read More <i class=  "fa fa-arrow-right" />
                    </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
            data-wow-delay="300ms"
          >
            <div class=  "services-one__single">
              <div class=  "services-one__img">
                <img
                  src="assets/images/product_images/Date Palm Mesh 1.jpg"
                  alt=""
                />
              </div>
              <div class=  "services-one__content">
                <h3 class=  "services-one__title">
                  <Link to="/date_palm_mesh">Date Palm Mesh</Link>
                </h3>
                <div class=  "services-one__read-more">
                  <Link to="/date_palm_mesh">
                    Read More <i class=  "fa fa-arrow-right" />
                    </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
            data-wow-delay="400ms"
          >
            <div class=  "services-one__single">
              <div class=  "services-one__img">
                <img
                  src="assets/images/product_images/FIBC Bulk Bag 2.jpg"
                  alt=""
                />
              </div>
              <div class=  "services-one__content">
                <h3 class=  "services-one__title">
                  <Link to="/fibc_bulk_bags">Fibc / Bulk bag</Link>
                </h3>
                <div class=  "services-one__read-more">
                  <Link to="/fibc_bulk_bags">
                    Read More <i class=  "fa fa-arrow-right" />
                    </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            class=  "col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
            data-wow-delay="500ms"
          >
            <div class=  "services-one__single">
              <div class=  "services-one__img">
                <img
                  src="assets/images/product_images/Plastic Granules 1.jpg"
                  alt=""
                />
              </div>
              <div class=  "services-one__content">
                <h3 class=  "services-one__title">
                  <Link to="/plastic_granules">Plastic Granules</Link>
                </h3>
                <div class=  "services-one__read-more">
                  <Link to="/plastic_granules">
                    Read More <i class=  "fa fa-arrow-right" />
                    </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</>

    );
  }


  export default Main;