import { Link } from "react-router-dom";
import Footer from "./Foot";

function Anet() {
    return (
        <>
        <div class=  "stricky-header stricked-menu main-menu">
          <div class=  "sticky-header__content" />
          {/* /.sticky-header__content */}
        </div>
        {/* /.stricky-header */}
        {/*Page Header Start*/}
        <section class=  "page-header">
          <div
            class=  "page-header-bg"
            style={{
              backgroundImage: 'url("assets/images/backgrounds/page-header-bg.jpg")'
            }}
          ></div>
          <div class=  "container">
            <div class=  "page-header__inner">
              <ul class=  "thm-breadcrumb list-unstyled">
                <li>
                  <Link to="/">Home </Link>
                </li>
                <li>
                  <span>/</span>
                </li>
                <li>
                  <Link to="/our_products">Products</Link>
                </li>
                <li>
                  <span>/</span>
                </li>
                <li>Agriculture Net</li>
              </ul>
              <h2>Agriculture Net</h2>
            </div>
          </div>
        </section>
        {/*Page Header End*/}
        {/*Service Details Start*/}
        <section class=  "service-details">
          <div class=  "container">
            <div class=  "row">
              <div class=  "col-xl-4 col-lg-5">
                <div class=  "service-details__left">
                  <div class=  "service-details__category">
                    <ul class=  "service-details__category-list list-unstyled">
                      <li>
                        <Link to="/pp_woven_fabric">
                          PP Woven Fabric <span class=  "fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/pp_woven_sack">
                          PP Woven Sack <span class=  "fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/date_palm_mesh">
                          Date Palm Mesh <span class=  "fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/fibc_bulk_bags">
                          FIBC / Bulk Bag
                          <span class=  "fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/plastic_granules">
                          Plastic Granules <span class=  "fa fa-angle-right" />
                        </Link>
                      </li>
                      <li class=  "active">
                      <Link to="/agriculture_net">
                          Agriculture Net <span class=  "fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/leno_mesh_fabric">
                          Leno Mesh Fabric <span class=  "fa fa-angle-right" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class=  "col-xl-8 col-lg-7">
                <div class=  "service-details__right">
                  <div class=  "service-details__img">
                    <img
                      src="assets/images/products_cover/Agriculture Net Cover.jpg"
                      alt=""
                    />
                  </div>
                  <div class=  "service-details__content">
                    <h3 class=  "service-details__content-title">
                      Agriculture Net{" "}
                    </h3>
                    <p
                      class=  "service-details__text-1"
                      style={{ textAlign: "justify" }}
                    >
                      Agriculture Nets are basically used to provide shade to Green
                      House in Agriculture sector which is the reason why they are
                      also known as Agro Nets. These Green Houses are made out of
                      Iron, Wooden or Plastic skeleton structures which are then
                      covered by shade net as per specific requirements. These shade
                      nets are made to provide various density shades ranging from 25%
                      to 95%. These Green Houses help in providing year round
                      protection from sunlight, heat, cold &amp; wind for rising o
                      season crops through the year. These Shade Nets are also custom
                      made. (As per customer requirements)
                    </p>
                  </div>
                  <div class=  "service-details__benefits">
                    <div class=  "row">
                      <div class=  "col-xl-6">
                        <div class=  "service-details__benefits-single">
                          <div class=  "service-details__benefits-img">
                            <img
                              src="assets/images/product_images/Agriculture Net 1.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class=  "col-xl-6">
                        <div class=  "service-details__benefits-single">
                          <div class=  "service-details__benefits-img">
                            <img
                              src="assets/images/product_images/Agriculture Net 2.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
      
    );
  }
  
  
  
  export default Anet;
  