import { Link } from "react-router-dom";
import Footer from "./Foot";
function PPWovenFabric(){
return(
    <>
  <div class=  "stricky-header stricked-menu main-menu">
    <div class=  "sticky-header__content" />
    {/* /.sticky-header__content */}
  </div>
  
  <section class=  "page-header">
    <div
      class=  "page-header-bg"
      style={{
        backgroundImage: 'url("assets/images/backgrounds/page-header-bg.jpg")'
      }}
    ></div>
    <div class=  "container">
      <div class=  "page-header__inner">
        <ul class=  "thm-breadcrumb list-unstyled">
          <li>
            <Link to="/">Home </Link>
          </li>
          <li>
            <span>/</span>
          </li>
          <li>
            <Link to="/our_products">Products</Link>
          </li>
          <li>
            <span>/</span>
          </li>
          <li>PP Woven Fabric</li>
        </ul>
        <h2>PP Woven Fabric</h2>
      </div>
    </div>
  </section>

  <section class=  "service-details">
    <div class=  "container">
      <div class=  "row">
        <div class=  "col-xl-4 col-lg-5">
          <div class=  "service-details__left">
            <div class=  "service-details__category">
              <ul class=  "service-details__category-list list-unstyled">
                <li class=  "active">
                  <Link to="/pp_woven_fabric">
                    PP Woven Fabric <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/pp_woven_sack">
                    PP Woven Sack <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/date_palm_mesh">
                    Date Palm Mesh <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/fibc_bulk_bags">
                    FIBC / Bulk Bag
                    <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/plastic_granules">
                    Plastic Granules <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/agriculture_net">
                    Agriculture Net <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/leno_mesh_fabric">
                    Leno Mesh Fabric <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
              </ul>
            </div>
          </div>
        </div>
        <div class=  "col-xl-8 col-lg-7">
          <div class=  "service-details__right">
            <div class=  "service-details__img">
              <img
                src="assets/images/products_cover/PP Woven Fabric Cover.jpg"
                alt=""
              />
            </div>
            <div class=  "service-details__content">
              <h3 class=  "service-details__content-title">
                PP Woven Fabric
              </h3>
              <p
                class=  "service-details__text-1"
                style={{ textAlign: "justify" }}
              >
                We are offer wide range the Industrial grade clean PP Woven
                Fabric worldwide. Our PP Woven Fabric is manufactured
                considering the precise procedures for the quality control by
                maintaining the strict quality standards, making sure the
                customer receives the quality Polypropylene Fabric with the
                right technical constructions and minimum defects.
              </p>
            </div>
            <p class=  "prod">PP WOVEN SINGLE COLOURED FABRIC</p>
            <div class=  "service-details__benefits">
              <div class=  "row">
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Fabric 4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Fabric 5.jpg"
                        alt=""
                      />
                    </div>
                    <div class=  "service-details__benefits-content"></div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Fabric 6.jpg"
                        alt=""
                      />
                    </div>
                    <div class=  "service-details__benefits-content"></div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Fabric 7.jpg"
                        alt=""
                      />
                    </div>
                    <div class=  "service-details__benefits-content"></div>
                  </div>
                </div>
              </div>
            </div>
            <p class=  "prod">PP WOVEN MULTI COLOURED FABRIC</p>
            <div class=  "service-details__benefits">
              <div class=  "row">
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Fabric 1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Fabric 2.jpg"
                        alt=""
                      />
                    </div>
                    <div class=  "service-details__benefits-content"></div>
                  </div>
                </div>
                <div class=  "col-xl-6">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product_images/PP Woven Fabric 3.jpg"
                        alt=""
                      />
                    </div>
                    <div class=  "service-details__benefits-content"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</>

);
}

export default PPWovenFabric;