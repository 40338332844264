import { Link, NavLink } from "react-router-dom";
import $ from 'jquery'

function Header() {



  const Open = () => {
    $('.custom-cursor').addClass('locked');
    $('.custom-cursor__cursor').addClass('custom-cursor__hover');
    $('.mobile-nav__wrapper').addClass('expanded');
    
  

  }
  return (
    <div class="page-wrapper">
      <header class="main-header clearfix">
        <div class="main-header__logo">
          <Link to="/">
            <img src="assets/images/resources/logo-1.png" alt="" />
          </Link>
        </div>
        <div class="main-header__top">
          <div class="main-header__top-inner">
            <div class="main-header__top-address">
              <ul class="list-unstyled main-header__top-address-list">
                <li>
                  <i class="icon">
                    <span class="fa fa-phone-alt" />
                  </i>
                  <div class="text">
                    <p>+91 906 77777 53</p>
                  </div>
                </li>
                <li>
                  <i class="icon">
                    <span class="fa fa-envelope" />
                  </i>
                  <div class="text">
                    <p>
                      <a href="mailto:info@gizmoind.in"> info@gizmoind.in</a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="main-header__top-right">
              <div class="main-header__top-menu-box">
                <ul class="list-unstyled main-header__top-menu">
                  <li>
                    <Link to="/contact_us">Contact </Link>
                  </li>
                </ul>
              </div>
              <div class="main-header__top-social-box">
                <div class="main-header__top-social">
                  <a href="https://www.facebook.com/gizmoindustries/">
                    <i class="fab fa-facebook-square" />
                  </a>
                  {/*<a to="/#"><i class="fab fa-twitter"></i></a>*/}
                  <a href="https://www.linkedin.com/">
                    <i class="fab fa-linkedin-in" />
                  </a>
                  <a href="https://www.instagram.com/gizmoindustries/">
                    <i class="fab fa-instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav class="main-menu clearfix">
          <div class="main-menu__wrapper clearfix">
            <div class="main-menu__wrapper-inner clearfix">
              <div class="main-menu__left">
                <div class="main-menu__main-menu-box">
                  <Link to="#" onClick={Open} class="mobile-nav__toggler">
                    <i class="fa fa-bars" />
                  </Link>
                  <ul class="main-menu__list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about_gizmo_industries">About </Link>
                    </li>
                    <li>
                      <Link to="/our_products">Products </Link>
                    </li>
                    <li>
                      <Link to="/career">Career </Link>
                    </li>
                    <li>
                      <Link to="/contact_us">Contact </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="main-menu__right">
                <div class="main-menu__call">
                  <div class="main-menu__call-icon">
                    <i class="fas fa-phone" />
                  </div>
                  <div class="main-menu__call-content">
                    <p>Call Anytime</p>
                    <a href="tel:+919067777753">+91 906 77777 53</a>
                  </div>
                </div>
                <div class="main-menu__btn-box">
                  <Link to="/contact_us" class="thm-btn main-menu__btn">
                    {" "}
                    <i class="fa fa-arrow-right" /> Free estimate
                  </Link>
                </div>
              </div>
              {/* <div class="main-menu__right">
                            <div class="main-menu__call">
                                <div class="main-menu__call-icon">
                                    <i class="fas fa-phone"></i>
                                </div>
                                <div class="main-menu__call-content">
                                    <p>For Inquiry</p>
                                    <a href="tel:+91 906 77777 53">+91 906 77777 53</a>
                                </div>
                            </div>
      
                        </div> */}
            </div>
          </div>
        </nav>
      </header>
      <div class="stricky-header stricked-menu main-menu">
        <div class="sticky-header__content" />
      </div>
    </div>

  );
}

export default Header;