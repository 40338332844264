import { Link } from "react-router-dom";
import Footer from "./Foot";

function NoPage() {
    return (
        <>
        <div class=  "stricky-header stricked-menu main-menu">
          <div class=  "sticky-header__content" />
        </div>
        
        <section class=  "page-header">
          <div
            class=  "page-header-bg"
            style={{
              backgroundImage: 'url("assets/images/backgrounds/page-header-bg.jpg")'
            }}
          ></div>
          <div class=  "container">
            <div class=  "page-header__inner">
              <ul class=  "thm-breadcrumb list-unstyled">
                <li>
                  <Link to="/">Home </Link>
                </li>
                <li>
                  <span>/</span>
                </li>
                <li>Career</li>
              </ul>
              <h2>Career</h2>
            </div>
          </div>
        </section>
        {/*Page Header End*/}
        <section class=  "container">
          <div style={{ marginBottom: 150, marginTop: 150 }}>
            <h1>404</h1>
            <p>Page Not Found</p>
          </div>
        </section>
        <Footer/>
      </>
      
    );
  }
  
  
  
  export default NoPage;