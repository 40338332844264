import { Link } from "react-router-dom";
import Footer from "./Foot";

function About() {
    return (
        <>
        <div class=  "stricky-header stricked-menu main-menu">
          <div class=  "sticky-header__content" />
          
        </div>

        <section class=  "page-header">
          <div
            class=  "page-header-bg"
            style={{
              backgroundImage: 'url("assets/images/backgrounds/page-header-bg.jpg")'
            }}
          ></div>
          <div class=  "container">
            <div class=  "page-header__inner">
              <ul class=  "thm-breadcrumb list-unstyled">
                <li>
                  <Link to="/">Home </Link>
                </li>
                <li>
                  <span>/</span>
                </li>
                <li>About</li>
              </ul>
              <h2>About us</h2>
            </div>
          </div>
        </section>
        {/*Page Header End*/}
        {/*About Three Start*/}
        <section class=  "about-three about-four">
          <div class=  "container">
            <div class=  "row">
              <div class=  "col-xl-12">
                <div class=  "about-three__right">
                  <div class=  "section-title text-left">
                    <div class=  "section-sub-title-box">
                      <p class=  "section-sub-title">About Gizmo</p>
                      <div class=  "section-title-shape-1">
                        <img
                          src="assets/images/shapes/section-title-shape-1.png"
                          alt="loading"
                        />
                      </div>
                    </div>
                    <h2 class=  "section-title__title headfont">Who We Are ?</h2>
                  </div>
                  <p class=  "about-three__text" style={{ textAlign: "justify" }}>
                    <b style={{ color: "black" }}>Gizmo Industries</b> was
                    conceptualized very early and in other way it is a brain child –
                    at present –We are already is in this field since the first decade
                    of 21st century. The entire lap of time was a process of gathering
                    and gaining thorough and deepest knowledge in the field of such
                    commodities.
                  </p>
                  <p>&nbsp;</p>
                  <p class=  "about-three__text" style={{ textAlign: "justify" }}>
                    When entire outline was concluded in the mind of us We started
                    converting the outline into a beautiful picture with the colours
                    of variants of manufacturing fine and high quality products such
                    as, Range of PP Woven Fabric, PP Woven Sack Bags, Leno Mesh
                    Fabric, Leno Mesh Bags, Date Palm Mesh Bags, Agriculture Net,
                    Fibc/Bulk Bags Etc.
                  </p>
                  <p>&nbsp;</p>
                  <p class=  "about-three__text" style={{ textAlign: "justify" }}>
                    But, this was not the end of the picture. Beautifully coloured
                    picture turned to become master piece by adding some special
                    effects of trading, merchandizing, exporting etc.
                  </p>
                  <p>&nbsp;</p>
                  <p class=  "about-three__text" style={{ textAlign: "justify" }}>
                    In nutshell, Gizmo Industries is engaged in variety of packaging
                    products that are essential for every industry and we serve in
                    such as Agriculture, Chemical, Construction, FMCG, etc.
                  </p>
                  <p>&nbsp;</p>
                  <p class=  "about-three__text" style={{ textAlign: "justify" }}>
                    We as a company have engraved a motto to serve better and better
                    to our valued patrons with the best quality of product, according
                    to the need and even, suggesting them the best options through
                    which the can get the value of their investment / paying money.
                  </p>
                  <p class=  "about-three__text" style={{ textAlign: "justify" }}>
                    To serve better and better by striving toward the best product to
                    gain the belief of each person who have associated with us is our
                    prime motive……
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class=  "about-three about-four">
          <div class=  "container">
            <div class=  "row">
              <div class=  "col-xl-6">
                <div class=  "about-three__left">
                  <div
                    class=  "about-three__img-box wow slideInLeft"
                    data-wow-delay="100ms"
                    data-wow-duration="2500ms"
                  >
                    <div class=  "about-three__img">
                      <img src="assets/images/mision.jpeg" alt="" />
                    </div>
                    <div class=  "about-three__img-two">
                      <img src="assets/images/vission.jpeg" alt="" />
                    </div>
                    <div class=  "about-three__border" />
                  </div>
                </div>
              </div>
              <div class=  "col-xl-6">
                <div class=  "about-three__right">
                  <div class=  "section-title text-left">
                    <div class=  "section-sub-title-box">
                      <p class=  "section-sub-title">Gizmo</p>
                      <div class=  "section-title-shape-1">
                        <img
                          src="assets/images/shapes/section-title-shape-1.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <h2 class=  "section-title__title headfont">
                      Mission &amp; Vision
                    </h2>
                  </div>
                  <p class=  "about-three__text " style={{ textAlign: "justify" }}>
                    <b style={{ color: "black" }}>Mission : </b>To build a bridge of
                    fruitful relationship with our precious patrons with
                    state-of-the-art and comprehensively intellectual services at all
                    the time. Our prime motto is to be a vital part of entire ethical
                    business community across the globe with our keen, enthusiastic,
                    noble and genuine whole-hearted efforts.
                  </p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p class=  "about-three__text" style={{ textAlign: "justify" }}>
                    <b style={{ color: "black" }}>Vision : </b>To be a genuine and
                    reputed business house by shaking hands with all the associates,
                    customers, vendors, suppliers and society as well. Believing into
                    snatch the results from the bottom of impossibilities, that is
                    possible with the help of our treasured patrons.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
      
    );
  }
  
  
  
  export default About;

