import { Link } from "react-router-dom";
import Footer from "./Foot";

function Datepalmmesh(){
    return(
        <>
  <div class=  "stricky-header stricked-menu main-menu">
    <div class=  "sticky-header__content" />
    {/* /.sticky-header__content */}
  </div>
  {/* /.stricky-header */}
  {/*Page Header Start*/}
  <section class=  "page-header">
    <div
      class=  "page-header-bg"
      style={{
        backgroundImage: 'url("assets/images/backgrounds/page-header-bg.jpg")'
      }}
    ></div>
    <div class=  "container">
      <div class=  "page-header__inner">
        <ul class=  "thm-breadcrumb list-unstyled">
          <li>
            <Link to="/">Home </Link>
          </li>
          <li>
            <span>/</span>
          </li>
          <li>
            <Link to="/our_products">Products</Link>
          </li>
          <li>
            <span>/</span>
          </li>
          <li>Date Palm Mesh</li>
        </ul>
        <h2>Date Palm Mesh</h2>
      </div>
    </div>
  </section>
  {/*Page Header End*/}
  {/*Service Details Start*/}
  <section class=  "service-details">
    <div class=  "container">
      <div class=  "row">
        <div class=  "col-xl-4 col-lg-5">
          <div class=  "service-details__left">
            <div class=  "service-details__category">
              <ul class=  "service-details__category-list list-unstyled">
                <li>
                  <Link to="/pp_woven_fabric">
                    PP Woven Fabric <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/pp_woven_sack">
                    PP Woven Sack <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li class=  "active">
                  <Link to="/date_palm_mesh">
                    Date Palm Mesh <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/fibc_bulk_bags">
                    FIBC / Bulk Bag
                    <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/plastic_granules">
                    Plastic Granules <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/agriculture_net">
                    Agriculture Net <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
                <li>
                  <Link to="/leno_mesh_fabric">
                    Leno Mesh Fabric <span class=  "fa fa-angle-right" />
                  </Link>
                      </li>
              </ul>
            </div>
          </div>
        </div>
        <div class=  "col-xl-8 col-lg-7">
          <div class=  "service-details__right">
            <div class=  "service-details__img">
              <img
                src="assets/images/products_cover/Date Palm Mesh Cover.jpg"
                alt=""
              />
            </div>
            <div class=  "service-details__content">
              <h3 class=  "service-details__content-title">Date Palm Mesh</h3>
              <p
                class=  "service-details__text-1"
                style={{ textAlign: "justify" }}
              >
                Mesh bags, also known as plant protector bags, are made of
                high-quality see-through mesh cloth with PE Material, which is
                UV resistant. We are currently supplies three main plant
                protector mesh bags, that is, fruit protection mesh bags, date
                harvest mesh bags, and fruit tree bags. The fruit protection
                mesh bags are well used for fruits and vegetable protection. Our
                date palm tree net mesh bag can eectively shield your ripening
                fruit. The fruit protection mesh bags are eco-friendly and
                widely used for garden and agricultural applications. The size,
                mesh and color of our plant protector mesh bags can be
                customized according to customers' needs
              </p>
              <p
                class=  "service-details__text-3"
                style={{ textAlign: "justify" }}
              >
                Date palm bags safely protect your valuable fruit. These date
                palm bags will eectively shield your ripening fruit from preying
                birds and insects. The bags on palm trees are also great for
                placing over the entire plant (depending on size), clusters of
                fruit, or an individual piece of fruit. The palm tree bags are
                typically applied one time before fruits' maturity and then
                removed during harvest. The palm tree net can assist in
                controlled pollination. The palm tree bags oer exclusion in a
                simple organic strategy that relies on preventing access to
                pest, their derived viruses, and disease. The date palm mesh bag
                will simply catch ripening dates and other fruit from falling
                onto the ground now contributed to a higher yield.
              </p>
            </div>
            <div class=  "service-details__benefits">
              <div class=  "row">
                <div class=  "col-xl-12">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img src="assets/images/product/g1.png" alt="" />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-12">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img src="assets/images/product/g2.png" alt="" />
                    </div>
                  </div>
                </div>
                <div class=  "col-xl-12">
                  <div class=  "service-details__benefits-single">
                    <div class=  "service-details__benefits-img">
                      <img
                        src="assets/images/product/dpm.png"
                        style={{ height: "500px !important" }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
  </>

    );

}

export default Datepalmmesh;